function marker(key) {
    return key;
}

/**
 * Generated bundle index. Do not edit.
 */

export { marker };

